import { useTranslation } from "react-i18next";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import { useMicrophone } from "./useMicrophone";
import { useMicrophoneDropdown } from "./useMicrophoneDropdown";
import { IToolbarItemGroup } from "@amzn/aws-euc-ui";

export const useMicrophoneWithDropdown = (appStreamEmbed: AppStreamSDK) => {
    const { t } = useTranslation();
    const microphoneButton = useMicrophone(appStreamEmbed);
    const microphoneDropdownButton = useMicrophoneDropdown(appStreamEmbed);
    return {
        groupItems: [microphoneButton, microphoneDropdownButton],
        label: t("toolbar.item.microphone.label"),
        className: "with-expand-button",
    };
};
