import { I18n } from "@amzn/aws-euc-ui";
import { useTranslation } from "react-i18next";

export const useFilesI18n = (): I18n => {
    const { t } = useTranslation();

    return {
        paginationAriaLabels: {
            nextPageLabel: t(
                "toolbar.item.files.content.table.pagination.nextPage.label"
            ),
            previousPageLabel: t(
                "toolbar.item.files.content.table.pagination.previousPage.label"
            ),
            pageLabel: (pageNumber: number) =>
                t("toolbar.item.files.content.table.pagination.page.label", {
                    pageNumber,
                }),
        },
        tableAriaLabels: {
            selectionGroupLabel: t(
                "toolbar.item.files.content.table.selectionGroup.label"
            ),
            allItemsSelectionLabel: ({ selectedItems }) => {
                if (selectedItems.length === 1) {
                    return t(
                        "toolbar.item.files.content.table.allItemsSelection.single.label"
                    );
                } else {
                    return t(
                        "toolbar.item.files.content.table.allItemsSelection.multiple.label",
                        { count: selectedItems.length }
                    );
                }
            },
            itemSelectionLabel: ({ selectedItems }, item) => {
                const isItemSelected = selectedItems.some(
                    (i) => i.displayName === item.displayName
                );
                if (isItemSelected) {
                    return t(
                        "toolbar.item.files.content.table.itemsSelection.selected.label",
                        { name: item.displayName }
                    );
                } else {
                    return t(
                        "toolbar.item.files.content.table.itemsSelection.unselected.label",
                        { name: item.displayName }
                    );
                }
            },
        },
        filteringAriaLabel: t(
            "toolbar.item.files.content.table.filtering.ariaLabel"
        ),
        filteringClearAriaLabel: t(
            "toolbar.item.files.content.table.filteringClear.ariaLabel"
        ),
        breadCrumbAriaLabel: t(
            "toolbar.item.files.content.table.breadCrumb.ariaLabel"
        ),
        breadCrumbExpandAriaLabel: t(
            "toolbar.item.files.content.table.breadCrumbExpand.ariaLabel"
        ),
        entry: t("toolbar.item.files.content.table.entry"),
        entries: t("toolbar.item.files.content.table.entries"),
        pageSize: t("toolbar.item.files.content.table.pageSize"),
        selectPageSize: t("toolbar.item.files.content.table.selectPageSize"),
        wrapLinesLabel: t("toolbar.item.files.content.table.wrapLines.label"),
        wrapLinesDescription: t(
            "toolbar.item.files.content.table.wrapLines.description"
        ),
        cancelLabel: t("toolbar.item.files.content.table.cancel.label"),
        confirmLabel: t("toolbar.item.files.content.table.confirm.label"),
        preferencesTitle: t(
            "toolbar.item.files.content.table.preferences.title"
        ),
        rename: t("toolbar.item.files.content.table.rename"),
        fileExplorerTitle: t(
            "toolbar.item.files.content.table.fileExplorer.title"
        ),
        download: t("toolbar.item.files.content.table.download"),
        uploading: t("toolbar.item.files.content.table.uploading"),
        uploadFiles: t("toolbar.item.files.content.table.uploadFiles"),
        noMatchesTitle: t("toolbar.item.files.content.table.noMatches.title"),
        noMatchesSubtitle: t(
            "toolbar.item.files.content.table.noMatches.subtitle"
        ),
        noFilesTitle: t("toolbar.item.files.content.table.noFiles.title"),
        noFilesSubtitle: t("toolbar.item.files.content.table.noFiles.subtitle"),
        dropZoneTitle: t("toolbar.item.files.content.table.dropZone.title"),
        dropZoneSubtitle: t(
            "toolbar.item.files.content.table.dropZone.subtitle"
        ),
        createFolder: t("toolbar.item.files.content.table.createFolder"),
        fileName: t("toolbar.item.files.content.table.fileName"),
        fileLastModified: t(
            "toolbar.item.files.content.table.fileLastModified"
        ),
        fileSize: t("toolbar.item.files.content.table.fileSize"),
        delete: t("toolbar.item.files.content.table.delete"),
        actions: t("toolbar.item.files.content.table.actions"),
        uploadFolder: t("toolbar.item.files.content.table.uploadFolder"),
        openFolder: t("toolbar.item.files.content.table.openFolder"),
        newFolderName: t("toolbar.item.files.content.table.newFolderName"),
        saveChanges: t("toolbar.item.files.content.table.saveChanges"),
        ignoreChanges: t("toolbar.item.files.content.table.ignoreChanges"),
        newFileName: t("toolbar.item.files.content.table.newFileName"),
        folder: t("toolbar.item.files.content.table.folder"),
        file: t("toolbar.item.files.content.table.file"),
        clearFilter: t("toolbar.item.files.content.table.clearFilter"),
    };
};
