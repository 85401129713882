import { AppStreamSDK } from "../../../../utils/AppStreamSDK";
import { useTranslation } from "react-i18next";
import { useFilesNotificationStore } from "../../../../hooks/useNotificationStore";
import { IFileExplorerApi, NotificationItem } from "@amzn/aws-euc-ui";
import { useNotification } from "../notification/useNotification";
import { useMemo } from "react";
import { EmbedConstants } from "@amzn/photon-portal-embed-sdk";

// https://pubs.opengroup.org/onlinepubs/007908799/xbd/glossary.html#tag_004_000_114
const INVALID_NAME_CHARS = ["/", "\0"];

export const useFilesApis = (appStreamSDK: AppStreamSDK) => {
    const { t } = useTranslation();

    const notificationList = useFilesNotificationStore(
        (store) => store.notificationList
    );

    const addNotification = useFilesNotificationStore(
        (store) => store.addNotification
    );

    const removeNotification = useFilesNotificationStore(
        (store) => store.removeNotification
    );

    const DELETE_FILE_ERROR: NotificationItem = useNotification({
        header: t("notification.fail.deleteFile.content"),
        removeNotification: removeNotification,
    });

    const LIST_FILE_ERROR: NotificationItem = useNotification({
        header: t("notification.fail.listFile.content"),
        removeNotification: removeNotification,
    });

    const RENAME_DIRECTORY_ERROR: NotificationItem = useNotification({
        header: t("notification.fail.renameDirectory.content"),
        removeNotification: removeNotification,
    });

    const DOWNLOAD_FILE_ERROR: NotificationItem = useNotification({
        header: t("notification.fail.downloadFile.content"),
        removeNotification: removeNotification,
    });

    const UPLOAD_FILE_ERROR: NotificationItem = useNotification({
        header: t("notification.fail.uploadFile.content"),
        removeNotification: removeNotification,
    });

    return useMemo<IFileExplorerApi>(
        () => ({
            // CreateDirectory error handled internally: https://tiny.amazon.com/107hpu5e4/codeamazpackAWSEblob865dsrc
            createDirectory: (path) => {
                return appStreamSDK.performActionPromise(
                    EmbedConstants.METHOD_CREATE_FOLDER,
                    {
                        remotePath: path,
                    }
                );
            },
            deleteFile: (path) =>
                appStreamSDK
                    .performActionPromise(EmbedConstants.METHOD_DELETE_FILE, {
                        remotePath: path,
                    })
                    .catch(() => addNotification(DELETE_FILE_ERROR)),
            list: (path) =>
                appStreamSDK.listFiles(path).catch((error) => {
                    // https://sim.amazon.com/issues/LOWA-9174
                    if (error.errorCode !== 400) {
                        // Only show ListFile API error once since we are using 3 retries with useQuery (to avoid multiple errors on screen from one fetch)
                        if (
                            !notificationList.find(
                                (item) => item.header === LIST_FILE_ERROR.header
                            )
                        ) {
                            addNotification(LIST_FILE_ERROR);
                        }
                    }
                    return [];
                }),
            renameDirectory: (src, dest) =>
                appStreamSDK
                    .performActionPromise(EmbedConstants.METHOD_RENAME_FILE, {
                        remotePath: src,
                        newPath: dest,
                    })
                    .catch(() => addNotification(RENAME_DIRECTORY_ERROR)),
            // RenameFile error handled internally: https://tiny.amazon.com/oxaj7lvc/codeamazpackAWSEblob865dsrc
            renameFile: (originalPath, newPath) =>
                appStreamSDK.performActionPromise(
                    EmbedConstants.METHOD_RENAME_FILE,
                    {
                        remotePath: originalPath,
                        newPath: newPath,
                    }
                ),
            retrieveFile: (path) =>
                appStreamSDK
                    .performActionPromise(EmbedConstants.METHOD_DOWNLOAD_FILE, {
                        remotePath: path,
                    })
                    .catch(() => addNotification(DOWNLOAD_FILE_ERROR)),
            storeFile: async (file, dir, onProgress) => {
                onProgress(0);

                try {
                    await appStreamSDK.performActionPromise(
                        EmbedConstants.METHOD_UPLOAD_FILE,
                        {
                            file: file,
                            remotePath: dir,
                        }
                    );
                } catch {
                    addNotification(UPLOAD_FILE_ERROR);
                }

                onProgress(file.size);
            },
            validateName: (name) => {
                if (
                    INVALID_NAME_CHARS.some((invalidChar) =>
                        name.includes(invalidChar)
                    )
                ) {
                    return t(
                        "toolbar.item.files.name.validation.invalidCharacter"
                    );
                }

                return null;
            },
        }),
        [appStreamSDK, JSON.stringify(notificationList)]
    );
};
