import { AppStreamSDK } from "../../../../utils/AppStreamSDK";
import {
    FileExplorer,
    IToolbarButtonActiveContentType,
    IToolbarButtonProps,
    IToolbarButtonStatefulModalProps,
    IToolbarButtonType,
    NotificationList,
    ToolbarActiveStateContentProps,
    ToolbarContainerItemContent,
    useToolbarCollapseStore,
} from "@amzn/aws-euc-ui";
import React, { memo } from "react";
import { Icon } from "@cloudscape-design/components";
import {
    INITIAL_FILE_EXPLORER_PATH,
    ToolbarItemId,
    ToolbarMode,
} from "../../../../constants/Toolbar";
import { useTranslation } from "react-i18next";
import { useFilesNotificationStore } from "../../../../hooks/useNotificationStore";
import { useFilesI18n } from "./useFilesI18n";
import { useFilesApis } from "./useFilesApis";

export const useFileToolbarItem = (
    appStreamSDK: AppStreamSDK,
    toolbarState: string
): IToolbarButtonProps | IToolbarButtonStatefulModalProps => {
    const { t } = useTranslation();
    const i18nStrings = useFilesI18n();
    const api = useFilesApis(appStreamSDK);

    const notificationList = useFilesNotificationStore(
        (store) => store.notificationList
    );

    const { setCollapsed } = useToolbarCollapseStore();

    const {
        isFileDownloadAllowed,
        isFileUploadAllowed,
    } = appStreamSDK.getUserInterfaceState();

    const FileToolbarItemContent = () => {
        return (
            <>
                <NotificationList
                    items={notificationList}
                    ariaLabel={t("notification.list.ariaLabel")}
                />
                <FileExplorer
                    api={api}
                    i18nStrings={i18nStrings}
                    rootPath={INITIAL_FILE_EXPLORER_PATH}
                    tableVariant={"embedded"}
                    contentDensity={"compact"}
                    textFilterProps={{
                        filteringPlaceholder: t(
                            "toolbar.item.files.content.table.filtering.placeholder"
                        ),
                    }}
                    allowedActions={{
                        download: isFileDownloadAllowed,
                        upload: isFileUploadAllowed,
                    }}
                />
            </>
        );
    };

    if (toolbarState === ToolbarMode.Docked) {
        return {
            type: IToolbarButtonType.STATEFUL,
            label: t("toolbar.item.files.label"),
            id: ToolbarItemId.FILES,
            icon: <Icon data-testid={"file-explorer-icon"} name="folder" />,
            onClick: () => {
                setCollapsed(false);
            },
            activeStateContentType: IToolbarButtonActiveContentType.MODAL,
            ActiveStateContent: memo(() => <FileToolbarItemContent />),
            ariaLabelCloseModal: t(
                "toolbar.item.files.content.close.ariaLabel"
            ),
        };
    }

    return {
        type: IToolbarButtonType.STATEFUL,
        activeStateContentType: IToolbarButtonActiveContentType.CONTAINER,
        id: ToolbarItemId.FILES,
        label: t("toolbar.item.files.label"),
        icon: <Icon data-testid={"file-explorer-icon"} name="folder" />,
        onClick: () => {
            setCollapsed(false);
        },
        ActiveStateContent: memo((props: ToolbarActiveStateContentProps) => (
            <ToolbarContainerItemContent
                close={props.close}
                closeButtonAriaLabel={t(
                    "toolbar.item.files.content.close.ariaLabel"
                )}
                closeButtonTitle={t("toolbar.item.content.close.title")}
            >
                <FileToolbarItemContent />
            </ToolbarContainerItemContent>
        )),
    };
};
