import React, { useState, useEffect } from "react";
import logo from "../../resources/WSW_logo.png";
import wsbLogo from "../../resources/WSB_logo_white.png";
import { ProgressIndicator } from "@amzn/aws-euc-ui";
import "./style.css";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isFeatureEnabled } from "../../configurations";

/** 
 Checking the "SessionStartedResponseTime" Metrics the start-time is about 35 seconds. 
 Adding 5 seconds more as a buffer.
 
 Metrics:
 us-west-2 : https://tiny.amazon.com/16p2jtakg/IsenLink
 us-east-1 : https://tiny.amazon.com/ic5pnoo0/IsenLink
 ap-south-1 : https://tiny.amazon.com/tjyq4lyc/IsenLink
*/
const SESSION_START_TIME = 40000;

const useSpinnerProgress = (time = 0) => {
    const [progress, setProgress] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevNumber) => {
                if (prevNumber === 100) {
                    clearInterval(interval);
                    return prevNumber;
                }
                return prevNumber + 1;
            });
        }, time / 100);

        return () => clearInterval(interval);
    }, []);

    return progress;
};

const Loader = ({ message }) => {
    const { t } = useTranslation();
    const progress = useSpinnerProgress(SESSION_START_TIME);
    const isDeterminateProgressBar =
        (message === "Connecting..." ||
            message === "Preparing your applications...") &&
        progress < 100;

    // Enum object mapping English messages from AS2 to localized keys
    const MessageEnum = {
        "Connecting...": t("euc.loading.screen.connecting.message"),
        "Resizing...": t("euc.loading.screen.resizing.message"),
        "Reserving your session...": t("euc.loading.screen.reserving.message"),
        "Preparing your applications...": t(
            "euc.loading.screen.connecting.message"
        ),
    };
    const localizedMessage =
        message in MessageEnum ? MessageEnum[message] : message;
    const logoToUse = isFeatureEnabled("rebrand") ? wsbLogo : logo;
    return (
        <div className={"euc-loader-container-style On-top"}>
            <ProgressIndicator
                header={
                    <div className="euc-loader-header-container">
                        <img
                            className="euc-loader-header-image"
                            src={logoToUse}
                        />
                    </div>
                }
                indicatorStyle={{
                    width: "260px",
                }}
                determinate={isDeterminateProgressBar}
                progress={progress}
                size="cover"
                footer={
                    <div className="euc-loader-footer-container">
                        {localizedMessage && (
                            <span className="euc-loader-footer-span">
                                {localizedMessage}
                            </span>
                        )}
                    </div>
                }
            />
        </div>
    );
};

Loader.propTypes = {
    message: PropTypes.string.isRequired,
};

export default Loader;
